import { graphql, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"

interface Alumni {
    name: string
    year: number
    school: string
    url: string
    logo: IGatsbyImageData
}
// gatsbyImageData(width: 400, height: 500, transformOptions: { fit: COVER, cropFocus: NORTH })
export const playerImage = graphql`
    fragment schoolImage on File {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
`

export const query = graphql`
    query {
        bentley: file(relativePath: { eq: "schools/bentley.png" }) {
            ...schoolImage
        }
        bridgewater: file(relativePath: { eq: "schools/bridgewater.png" }) {
            ...schoolImage
        }
        clark: file(relativePath: { eq: "schools/clark.jpg" }) {
            ...schoolImage
        }
        curry: file(relativePath: { eq: "schools/curry.jpg" }) {
            ...schoolImage
        }
        carleton: file(relativePath: { eq: "schools/carleton.jpg" }) {
            ...schoolImage
        }
        ecsu: file(relativePath: { eq: "schools/ecsu.jpg" }) {
            ...schoolImage
        }
        fitchburg: file(relativePath: { eq: "schools/fitchburg.jpg" }) {
            ...schoolImage
        }
        norwich: file(relativePath: { eq: "schools/norwich.png" }) {
            ...schoolImage
        }
        lesley: file(relativePath: { eq: "schools/lesley.jpg" }) {
            ...schoolImage
        }
        ric: file(relativePath: { eq: "schools/ric.png" }) {
            ...schoolImage
        }
        salemstate: file(relativePath: { eq: "schools/salemstate.png" }) {
            ...schoolImage
        }
        simmons: file(relativePath: { eq: "schools/simmons.jpg" }) {
            ...schoolImage
        }
        smith: file(relativePath: { eq: "schools/smith.png" }) {
            ...schoolImage
        }
        stevenson: file(relativePath: { eq: "schools/stevenson.jpg" }) {
            ...schoolImage
        }
        stonehill: file(relativePath: { eq: "schools/stonehill.png" }) {
            ...schoolImage
        }
        umassdartmouth: file(relativePath: { eq: "schools/umass-dartmouth.jpg" }) {
            ...schoolImage
        }
        wentworth: file(relativePath: { eq: "schools/wentworth.png" }) {
            ...schoolImage
        }
        wheaton: file(relativePath: { eq: "schools/wheaton.png" }) {
            ...schoolImage
        }
        worcester: file(relativePath: { eq: "schools/worcester.png" }) {
            ...schoolImage
        }
    }
`

interface Data {
    bentley: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    bridgewater: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    carleton: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    clark: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    curry: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    ecsu: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    fitchburg: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    lesley: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    norwich: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    ric: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    salemstate: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    simmons: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    smith: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    stevenson: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    stonehill: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    umassdartmouth: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    wentworth: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    wheaton: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    worcester: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
}

const Alumni: React.FC<PageProps<Data>> = ({ data }) => {
    const alumni: Alumni[] = [
        {
            name: "Abby Thompson",
            school: "Eastern Connecticut State University",
            year: 2022,
            url: "https://gowarriorathletics.com/sports/softball",
            logo: data.ecsu.childImageSharp.gatsbyImageData,
        },
        {
            name: "Holly Swinamer",
            school: "Lesley University",
            year: 2021,
            url: "https://lesleyathletics.com/sports/sball/index",
            logo: data.lesley.childImageSharp.gatsbyImageData,
        },
        {
            name: "Morgan Collupy",
            school: "Rhode Island College",
            year: 2021,
            url: "https://goanchormen.com/sports/softball",
            logo: data.ric.childImageSharp.gatsbyImageData,
        },
        {
            name: "Kiera Parker",
            school: "Stevenson University",
            year: 2021,
            url: "https://www.gomustangsports.com/sports/sball/index",
            logo: data.stevenson.childImageSharp.gatsbyImageData,
        },
        {
            name: "Kate Hancock",
            school: "Curry College",
            year: 2021,
            url: "https://www.curryathletics.com/sports/sball/index",
            logo: data.curry.childImageSharp.gatsbyImageData,
        },
        {
            name: "Liliana Rolfe",
            school: "Norwich University",
            year: 2020,
            url: "https://www.norwichathletics.com/sports/sball/index",
            logo: data.norwich.childImageSharp.gatsbyImageData,
        },
        {
            name: "Ally Norton",
            school: "Carleton College",
            year: 2020,
            url: "https://athletics.carleton.edu/sports/softball",
            logo: data.carleton.childImageSharp.gatsbyImageData,
        },
        {
            name: "Emma Aldenberg",
            school: "Smith College",
            year: 2020,
            url: "https://smithpioneers.com/sports/softball",
            logo: data.smith.childImageSharp.gatsbyImageData,
        },
        {
            name: "Madison Berardi",
            school: "Wentworth Institute of Technology",
            year: 2020,
            url: "https://www.wentworthathletics.com/sports/sball/index",
            logo: data.wentworth.childImageSharp.gatsbyImageData,
        },
        {
            name: "Kara Bendanillo",
            school: "Salem State",
            year: 2019,
            url: "https://salemstatevikings.com/sports/sball/index",
            logo: data.salemstate.childImageSharp.gatsbyImageData,
        },
        {
            name: "Alex Sheehan",
            school: "Salem State",
            year: 2019,
            url: "https://salemstatevikings.com/sports/sball/index",
            logo: data.salemstate.childImageSharp.gatsbyImageData,
        },
        {
            name: "Mady Bendanillo",
            school: "Wheaton College",
            year: 2019,
            url: "https://www.wheatoncollegelyons.com/sports/sball/index",
            logo: data.wheaton.childImageSharp.gatsbyImageData,
        },
        {
            name: "Sarah Cahill",
            school: "Fitchburg State",
            year: 2019,
            url: "https://www.fitchburgfalcons.com/sports/sball/index",
            logo: data.fitchburg.childImageSharp.gatsbyImageData,
        },
        {
            name: "Marissa Liberti",
            school: "UMass Dartmouth",
            year: 2019,
            url: "https://corsairathletics.com/sports/softball",
            logo: data.umassdartmouth.childImageSharp.gatsbyImageData,
        },
        {
            name: "Kaylee Mankins",
            school: "Bentley University",
            year: 2019,
            url: "https://www.bentleyfalcons.com/sports/sball/index",
            logo: data.bentley.childImageSharp.gatsbyImageData,
        },
        {
            name: "Ally Kuchy",
            school: "Bridgewater State University",
            year: 2018,
            url: "https://www.bsubears.com/sports/sball/index",
            logo: data.bridgewater.childImageSharp.gatsbyImageData,
        },
        {
            name: "Marjorie Guerrier",
            school: "Curry College",
            year: 2018,
            url: "https://www.curryathletics.com/sports/sball/index",
            logo: data.curry.childImageSharp.gatsbyImageData,
        },
        {
            name: "Victoria Alexander",
            school: "Worcester State",
            year: 2018,
            url: "https://www.wsulancers.com/sports/sball/index",
            logo: data.worcester.childImageSharp.gatsbyImageData,
        },
        {
            name: "Lindsey Allen",
            school: "Bentley University",
            year: 2017,
            url: "https://www.bentleyfalcons.com/sports/sball/index",
            logo: data.bentley.childImageSharp.gatsbyImageData,
        },
        {
            name: "Lindsay Whittles",
            school: "Wheaton College",
            year: 2017,
            url: "https://www.wheatoncollegelyons.com/sports/sball/index",
            logo: data.wheaton.childImageSharp.gatsbyImageData,
        },
        {
            name: "Maille Bennett",
            school: "Stonehill College",
            year: 2017,
            url: "https://www.stonehillskyhawks.com/sports/sball/index",
            logo: data.stonehill.childImageSharp.gatsbyImageData,
        },
        {
            name: "Devynn Ledgard",
            school: "Simmons College",
            year: 2017,
            url: "http://athletics.simmons.edu/sports/sball/index",
            logo: data.simmons.childImageSharp.gatsbyImageData,
        },
        {
            name: "Beth Cronin",
            year: 2016,
            school: "Stonehill College",
            url: "https://www.stonehillskyhawks.com/sports/sball/index",
            logo: data.stonehill.childImageSharp.gatsbyImageData,
        },
        {
            name: "Madison O’Gryzek",
            year: 2015,
            school: "UMass Dartmouth",
            url: "https://corsairathletics.com/sports/softball",
            logo: data.umassdartmouth.childImageSharp.gatsbyImageData,
        },
        {
            name: "Taylor Erickson",
            year: 2015,
            school: "Clark University",
            url: "https://www.clarkathletics.com/sports/sball/index",
            logo: data.clark.childImageSharp.gatsbyImageData,
        },
    ]

    return (
        <Layout>
            <SEO title="Alumni" description="Some of the places our players have gone on to play at." />
            <section className="hero is-dark mt-6">
                <div className="hero-body">
                    <div className="container">
                        <p className="title">CM Thunder Gold Alumni</p>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="columns is-multiline">
                        {alumni.map(alum => (
                            <div
                                className="column is-full-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
                                key={alum.name}
                            >
                                <div className="card">
                                    <div className="card-image">
                                        <figure className="image is-4by3">
                                            <img src={alum.logo.images.fallback?.src} alt={alum.school} />
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <div className="media">
                                            <div className="media-content">
                                                <p className="title is-4">{alum.name}</p>
                                                <p className="subtitle is-6">
                                                    <a target="_blank" rel="noreferrer" href={alum.url}>
                                                        {alum.year} - {alum.school}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Layout >
    )
}

export default Alumni
